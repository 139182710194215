import { initializeApp, getApp, getApps } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyAawkXggIS2zKgStn6YPnmKCe2RlQQsCwQ",
    authDomain: "flow-d5b5b.firebaseapp.com",
    databaseURL: "https://flow-d5b5b.firebaseio.com",
    projectId: "flow-d5b5b",
    storageBucket: "flow-d5b5b.appspot.com",
    messagingSenderId: "954018594033",
    appId: "1:954018594033:web:c1d3e70d7374796b9dbd50",
    measurementId: "G-0WZLYWY2PW"
};

let firebase;

if (!getApps().length) {
    firebase = initializeApp(firebaseConfig); 
} else {     
    firebase = getApp(); 
}

export const db = getFirestore(firebase);
export const auth = getAuth();
export const provider = new GoogleAuthProvider();