import React, {useEffect, useState} from 'react';

import {Link} from 'react-router-dom'

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

const Nav = props => {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

    useEffect(() => {
        function handleResize() {
        setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
    }, []);

    return(
        <div className="nav">
            <div className="container" style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                <p className="nav-header"><span className="heavy">Trew</span> Holographic Technologies</p>
                {
                    windowDimensions.width < 0 ? (
                        <></>
                    ) : (
                        <div className="nav-links">
                        <p className="nav-link">Home</p>
                        <p className="nav-link">About</p>
                        <p className="nav-link">Contact</p>
                        <div className="button primary">
                            <Link to='/login'>Login</Link>
                        </div>
                        <div className="button light">
                            <Link to='/signup'>Sign Up</Link>
                        </div>
                    </div>
                    )
                }
            </div>
        </div>
    )
}

export default Nav;