import React from 'react';

import {useHistory} from 'react-router-dom';

const AuthComplete = props => {
    const history = useHistory();   

    return (
        <div className="auth-background">
            <div className="auth-panel">
                <h1>Coming <span>Soon</span>.</h1>
                <p>Authentication is complete, but we don't have anything to show here yet. Make sure to check back in the future.</p>

                <button className="button primary colored" type="button" onClick={() => history.push('/')}>
                    Go Home
                </button>
            </div>
        </div>
    )
}

export default AuthComplete;