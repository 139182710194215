import React, {useState, useRef, useEffect} from 'react';

import {auth, db} from '../firebase';
import {signInWithEmailAndPassword} from 'firebase/auth';

import {useHistory} from 'react-router-dom';

import {Form, Field} from 'react-final-form';

import * as THREE from 'three'
import p5 from 'p5';
import TRUNK from '../vanta.trunk.min.js';

const Login = props => {
    const [formError, setFormError] = useState(false);
    const [vantaEffect, setVantaEffect] = useState(0)
    const history = useHistory();
    const bg = useRef(null);

    // useEffect(() => {
    //     if (!vantaEffect) {
    //         setVantaEffect(TRUNK({
    //             THREE: THREE,
    //             p5: p5,
    //             el: bg.current,
    //             backgroundColor:'#ED3C58',
    //             color: "#e45c71",
    //             minHeight: 200.00,
    //             minWidth: 200.00,
    //             scale: 1.00,
    //             scaleMobile: 1.00,
    //             spacing: 10,
    //             chaos: 10.00
    //         }))
    //         }
    // }, [vantaEffect])

    // FORM

    const onSubmit = async vals => {

        signInWithEmailAndPassword(auth, vals.email, vals.password).then((userCredential) => {
            // Signed in 
            const user = userCredential.user;
            history.push('/complete');
            
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            alert("There was an error logging you in." + "\n\n" + "Specific Error: " + errorCode );
        });
    }

    // VALIDATORS

    const required = (value) => (value ? undefined : "This field is required.");
    const mustContainAt = (value) => {
        let result;

        try {
            if (value.includes('@')) {
                result = undefined;
            } else {
                result = "Your email must contain an @ symbol.";
            }
        } catch (e) {
            result = undefined;
        }
        
        return result;
    };
    const mustContainNumbers = (value) => (/\d/.test(value) ? undefined : "Your password must contain at least one number.");
    const mustContainUppercase = (value) => (value.match(/[A-Z]/) ? undefined : "Your password must contain at least one UPPERCASE letter.");
    const mustBeLonger = (value) => {
        let result;

        try {
            let length = value.length;

            if (length > 8) {
                result = undefined;
            } else {
                result = "Your password must be at least 8 characters long.";
            }
        } catch (e) {
            result = undefined;
        }
        
        return result;
    }

    useEffect(() => {
        console.log("r")
    })

    const composeValidators = (...validators) => (value) => validators.reduce((error, validator) => error || validator(value), undefined);

    return (
        <div className="auth-background" ref={bg}>
            <div className="auth-panel">
                <h1>Welcome back to <span>MyTrew</span>.</h1>
                <p>Enter your account details below, we'll take care of the rest.</p>
                <Form onSubmit={onSubmit} render={
                    ({handleSubmit}) => (
                        <form className="auth-form" onSubmit={handleSubmit}>
                            <Field name="email" validate={composeValidators(required, mustContainAt)} render={
                                ({input, meta}) => (
                                    <>
                                        <input autoComplete="email" type="text" {...input} placeholder="Enter your email..." />
                                        {meta.touched && <p className="error">{meta.error}</p>}
                                    </>
                                )
                            } />
                            <Field name="password" validate={required} render={
                                ({input, meta}) => (
                                    <>
                                        <input autoComplete="new-password" type="password" {...input} placeholder="Enter your password..." />
                                        {meta.touched && <p className="error">{meta.error}</p>}
                                    </>
                                )
                            } />
                            
                            <button className="button primary colored" type="submit">
                                Log In
                            </button>
                        </form>
                    )
                } />

                <p className="auth-change">Don't have an account? Sign in instead.</p>
            </div>
        </div>
    )
}

export default Login;