import React from 'react';

import watch from '../images/watch.svg'
import assistant from '../images/assistant.svg'
import custom from '../images/custom.svg';

const Details = props => {
    return (
        <div className="container">
            <div className="columns">
                <div className="column">
                    <h3 className="detail-title"><span className="color-primary">Holographic images</span> are<br />the next big thing.</h3>
                    <p className="detail-text">The Trew watch will be capable of projecting a holographic image of your screen two meters above the front display that you can also interact with using gestures. </p>
                </div>
                <div className="column column-image">
                    <img src={watch} alt="" />
                </div>
            </div>
            <div className="columns">
                <div className="column column-image">
                    <img src={assistant} alt="" />
                </div>
                <div className="column">
                    <h3 className="detail-title">A <span className="color-primary">virtual assistant</span><br />to help you get things done.</h3>
                    <p className="detail-text">Our voice assistant Nora is included to make sure you can get things done saving you time. Nora is your personal companion who you can take a test with to determine your personality and the way you like to do things. </p>
                </div>
            </div>
            <div className="columns">
                <div className="column">
                    <h3 className="detail-title"><span className="color-primary">Customise</span> your watch<br />to fit your preferences.</h3>
                    <p className="detail-text">You will be able to change the RGB bezel that allows you to set up light notifications with a programmed color. Or when you have the time displayed also enjoy an emoji for fun.</p>
                </div>
                <div className="column column-image">
                    <img src={custom} alt="" />
                </div>
            </div>
        </div>
    )
}

export default Details;