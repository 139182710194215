import './css/styles.scss';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import Home from './pages/Home';
import About from './pages/About';
import Details from './pages/Details';
import Aboutus from './pages/Aboutus';
import Footer from './pages/Footer';
import Signup from './pages/Signup';
import Login from './pages/Login';
import AuthComplete from './pages/AuthComplete';

import { Field, Form } from 'react-final-form';

import Box from './components/structural/Box';

import blob from './images/blob.svg';

function App() {
  const onSubmit = (vals) => {
    console.log(vals)
  }

  return (
    <Router>
        <Switch>
          <Route exact path="/">
            <Home />
            <About />
            <Details />
            <Aboutus />
            <div className="container">
              <Box>
                <img src={blob} className="blob" />
                <p className="box-title">Ready for launch?</p>
                <p className="box-text">Be in the know by adding yourself to our mailing list. Type your email into the box below, and don't worry, we wont share it with any third parties.</p>
                <Form onSubmit={onSubmit}>
                  {({handleSubmit}) => (
                    <form onSubmit={handleSubmit}>
                      <Field name='email'>
                        {({input}) => (
                          <input type="text" placeholder="Enter your email here..." className="box-input" {...input}/>
                        )}
                      </Field>
                      <button type="submit" className="button primary">
                          Join the mailing list
                      </button>
                    </form>
                  )}
                </Form>
              </Box>
            </div>
            <Footer />
          </Route>
          <Route exact path='/login'>
            <Login />
          </Route>
          <Route exact path='/signup'>
            <Signup />
          </Route>
          <Route exact path='/complete'>
            <AuthComplete />
          </Route>
        </Switch>
    </Router>
  );
}

export default App;
