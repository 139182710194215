import React, {useState} from 'react';

import {auth, db} from '../firebase';
import {createUserWithEmailAndPassword} from 'firebase/auth';
import { doc, setDoc } from "firebase/firestore"; 

import {useHistory} from 'react-router-dom';

import {Form, Field} from 'react-final-form';

const Signup = props => {
    const [formError, setFormError] = useState(false);
    const history = useHistory();

    // FORM

    const onSubmit = async vals => {
        if (vals.password !== vals.cpassword) {
            setFormError(true);
            return;
        }

        createUserWithEmailAndPassword(auth, vals.email, vals.password).then((userCredential) => {
            // Signed in 
            const user = userCredential.user;

            setDoc(doc(db, "cities", user.uid), {
                name: vals.name,
                email: vals.email,
            }).then(() => {
                history.push('/complete');
            }).catch((e) => {
                alert("There was an issue writing your details to the database.\n\n You will be signed in normally, but make sure to go to your profile and fill out any details we have missing." + "\n\nSpecific Error: " + e);
            });
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            alert("There was an error signing you up." + "\n\n" + "Specific Error: " + errorCode );
        });
    }

    // VALIDATORS

    const required = (value) => (value ? undefined : "This field is required.");
    const mustContainAt = (value) => {
        let result;

        try {
            if (value.includes('@')) {
                result = undefined;
            } else {
                result = "Your email must contain an @ symbol.";
            }
        } catch (e) {
            result = undefined;
        }
        
        return result;
    };
    const mustContainNumbers = (value) => (/\d/.test(value) ? undefined : "Your password must contain at least one number.");
    const mustContainUppercase = (value) => (value.match(/[A-Z]/) ? undefined : "Your password must contain at least one UPPERCASE letter.");
    const mustBeLonger = (value) => {
        let result;

        try {
            let length = value.length;

            if (length > 8) {
                result = undefined;
            } else {
                result = "Your password must be at least 8 characters long.";
            }
        } catch (e) {
            result = undefined;
        }
        
        return result;
    }

    const composeValidators = (...validators) => (value) => validators.reduce((error, validator) => error || validator(value), undefined);

    return (
        <div className="auth-background">
            <div className="auth-panel">
                <h1>Welcome to <span>MyTrew</span>.</h1>
                <p>Enter your details below to get started.</p>
                <Form onSubmit={onSubmit} render={
                    ({handleSubmit}) => (
                        <form className="auth-form" onSubmit={handleSubmit}>
                            <Field name="name" validate={required} render={
                                ({input, meta}) => (
                                    <>
                                        <input autoComplete="name" type="text" {...input} placeholder="Enter your name..." />
                                        {meta.touched && <p className="error">{meta.error}</p>}
                                    </>
                                )
                            } />
                            <Field name="email" validate={composeValidators(required, mustContainAt)} render={
                                ({input, meta}) => (
                                    <>
                                        <input autoComplete="email" type="text" {...input} placeholder="Enter your email..." />
                                        {meta.touched && <p className="error">{meta.error}</p>}
                                    </>
                                )
                            } />
                            <Field name="password" validate={composeValidators(required, mustContainNumbers, mustContainUppercase, mustBeLonger)} render={
                                ({input, meta}) => (
                                    <>
                                        <input autoComplete="new-password" type="password" {...input} placeholder="Enter your password..." />
                                        {meta.touched && <p className="error">{meta.error}</p>}
                                    </>
                                )
                            } />
                            <Field name="cpassword" validate={required} render={
                                ({input, meta}) => (
                                    <>
                                        <input type="password" {...input} placeholder="Confirm your password..." />
                                        {meta.touched && <p className="error">{meta.error}</p>}
                                    </>
                                )
                            } />
                            
                            <button className="button primary colored" type="submit">
                                Sign Up
                            </button>
                        </form>
                    )
                } />

                <p className="auth-change">Already have an account? Log in instead.</p>
            </div>
        </div>
    )
}

export default Signup;