import React from 'react';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faBatteryFull, faComments } from '@fortawesome/free-solid-svg-icons';
import {faBluetooth} from '@fortawesome/free-brands-svg-icons'

const About = props => {
    return (
        <>
            <p className="about-text">Building the future.<br /><br />Right now.</p>
            <div className="about-section">
                <div>
                    <FontAwesomeIcon icon={faBatteryFull} size="4x" color="#4a4a4a" />
                    <p className="about-card-title">Supercharge</p>
                    <p className="about-card-text">Energise with long lasting battery life to support you throughout your daily routine.</p>
                </div>
                <div>
                    <FontAwesomeIcon icon={faBluetooth} size="4x" color="#4a4a4a" />
                    <p className="about-card-title">Connect</p>
                    <p className="about-card-text">Energise with long lasting battery life to keep you going for up to three days.</p>
                </div>
                <div>
                    <FontAwesomeIcon icon={faComments} size="4x" color="#4a4a4a" />
                    <p className="about-card-title">Interact</p>
                    <p className="about-card-text">Energise with long lasting battery life to support you throughout your daily routine.</p>
                </div>
            </div>
        </>
    )
}

export default About;