import React from 'react';

import Nav from '../components/navigation/Nav';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'

import splashImage from '../images/welcome.svg';
import blob from '../images/blob.svg';
import blob2 from '../images/blob-2.svg';

const Home = props => {
    return (
        <>
            <div className="splash">
                <Nav />
                <img className="splash-image" src={splashImage} alt="" />
                <img className="blob-image" src={blob} alt="" />
                <img className="blob2-image" src={blob2} alt="" />
                <div className="container">
                    <h1 className="splash-title">Presenting: Trew Watch</h1>
                    <p className="splash-para">
                        A revolutionary new smartwatch that utilises holographic technology to provide the most immersive user experience ever.
                    </p>
                    <div className="button-container">
                        <div className="button primary" style={{marginLeft: 0}}>
                            Learn More
                        </div>
                        <div className="button light">
                            Try the Beta
                        </div>
                    </div>
                </div>
            </div>
            <div className="show-more-icon" >
                <FontAwesomeIcon icon={faChevronDown}/>
            </div>
        </>
    )
}

export default Home;