import React from 'react';

const Footer = props => {
    return (
        <div className="footer-container">
            <div className="container">
                <div className="footer-nav">
                    <p className="nav-header dark"><span className="heavy dark">Trew</span> Holographic Technologies</p>
                    <div className="nav-links">
                        <p className="nav-link dark">Home</p>
                        <p className="nav-link dark">About</p>
                        <p className="nav-link dark">Contact</p>
                        <div className="button primary colored">
                            Login
                        </div>
                        <div className="button light colored">
                            Signup
                        </div>
                    </div>
                </div>
                <div className="footer-nav">
                    <p className="nav-header dark" style={{opacity: 0.6, fontSize: 16}}>&#169; Trew Holographic Technologies, 2021. All rights reserved.</p>
                    <p className="nav-header dark" style={{opacity: 0.6, fontSize: 16}}>For enquiries: support@mytrew.com</p>
                </div>
            </div>
        </div>
    )
}

export default Footer;