import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'

import rose from '../images/rose.svg';

const Aboutus = props => {
    return (
        <div className="aboutus-container">
            <div className="container">
                <div className="columns">
                    <div>
                        <p className="about-box">A social community. <br />Built with you in mind.</p>
                    </div>
                    <div className="column">
                        <img src={rose} className="rose-line" />
                        <p className="aboutus-text">MyTrew is our social community that will allow you to interact with Nora as well as connect with others and share your experiences with people like you and to get things off your chest, take a load off and relax while you can also play some amazing games. <br /><br /> Create an account and get started now. If you are not ready for that commitment yet then you can subscribe to our newsletter to stay updated until you are. No pressure.</p>
                        <div className="button-container colored">
                            <div className="button primary colored" style={{marginLeft: 0}}>
                                Sign Up
                            </div>
                            <div className="button light colored">
                                Get reminded
                            </div>
                        </div>
                    </div>
                </div>
                <div className="columns">
                    <div>
                        <p className="about-box left">A passionate team. <br />Loving every minute.</p>
                    </div>
                    <div className="column">
                        <img src={rose} className="rose-line" />
                        <p className="aboutus-text">We are a small team that has spent countless hours of planning and research for us to say that we can create our holographic watch.<br /><br />Holographic technology is in fact used today in many aspects of our lives. It’s about time that there is a smartwatch that will be our favorite accessory. Please subscribe to our newsletter to receive updates on our ongoing progress to create the world’s first holographic smartwatch. </p>
                        <div className="button-container colored">
                            <div className="button primary colored" style={{marginLeft: 0}}>
                                Learn more about us
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Aboutus;